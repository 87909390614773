
import { Vue, Component } from 'vue-property-decorator';

import $app from '@/plugins/modules';
import { Common } from '@/common';
import { System } from '@/modules/system';
import { Admin } from '@/modules/admin'
import { IRolesFilter, IRolesSearch } from '../types';
import { IUserRole, ITableHeader, IPagination } from '../../system/types';

@Component
export default class RolesView extends Vue {
  common = $app.module(Common);
  system = $app.module(System);
  admin = $app.module(Admin);

  loading = false;

  filter: IRolesFilter = $app.clone(this.admin.$store.RolesSearch.filter);

  addItem = false;
  addName = '';

  showItem = false;
  processItem = false;
  selectedItem: IUserRole | null = null;

  get headers(): Array<ITableHeader> {
    return [
      {
        text: $app.i18n('system.Id'),
        value: 'RoleId',
        align: 'left',
        width: '20%'
      },
      {
        text: $app.i18n('system.Name'),
        value: 'RoleName',
        align: 'left',
        width: '80%'
      }
    ];
  }

  get search(): IRolesSearch {
    return this.admin.$store.RolesSearch;
  }

  async findNext(pagination: IPagination) {
    if (!this.loading) {
      this.loading = true;
      await this.admin.$store.findRoles(pagination);
      this.loading = false;
    }
  }

  async findFirst() {
    this.loading = false;
    const request = this.system.$module.newSearch(this.admin.$store.RolesSearch, this.filter);
    this.admin.$store.setRolesSearch({
      items: [],
      filter: request
    });
    this.findNext(request);
  }

  async store(item: IUserRole) {
    this.processItem = true;
    try {
      await this.admin.$store.storeRole(item);
      this.showItem = false;
    } catch (err) {
      $app.pushError(err);
    }
    this.processItem = false;
  }

  async remove(item: IUserRole) {
    this.processItem = true;
    try {
      await this.admin.$store.removeRole(item);
      this.showItem = false;
    } catch (err) {
      $app.pushError(err);
    }
    this.processItem = false;
  }

  async open(item: IUserRole) {
    try {
      this.selectedItem = await this.admin.$store.loadRole(item);
      this.showItem = true;
    } catch (err) {
      $app.pushError(err);
    }
  }

  append() {
    this.addName = '';
    this.addItem = true;
  }

  async doAppend() {
    this.processItem = true;
    try {
      const newItem: IUserRole = {
        id: null,
        name: this.addName,
        processing: false,
        userId: null,
        allowed: true,
        accesses: [],
        code: null,
        descr: null
      };

      this.selectedItem = await this.admin.$store.storeRole(newItem);
      this.addItem = false;
      this.showItem = true;
    } catch (err) {
      $app.pushError(err);
    }
    this.processItem = false;
  }

  mounted() {
    if (this.search.filter.totals === 0) {
      this.findFirst();
    }
  }
}
